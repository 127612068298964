<template>
    <div class="store-login">
        <div class="login">
            <div class="box">
                <div class="title">StoreDisk 登录</div>
                <StoreInput class="input" v-model.trim="user.username">请输入用户名</StoreInput>
                <StoreInput class="input" v-model.trim="user.password" type="password">请输入密码</StoreInput>
                <div class="buttons">
                    <StoreButton class="button" @click="login">登录</StoreButton>
                    <StoreButtonWrite class="button" @click="$router.push('/register')">注册</StoreButtonWrite>
                </div>
                <StoreFooter class="footer"></StoreFooter>
            </div>
        </div>
        <StoreTips v-model:show="tips.show">{{tips.text}}</StoreTips>
    </div>
</template>
<script>
import StoreFooter from "@/components/StoreFooter.vue"
import StoreButton from "@/components/StoreButton.vue"
import StoreInput from '@/components/StoreInput.vue'
import StoreButtonWrite from "@/components/StoreButtonWrite.vue"
import StoreTips from "@/components/StoreTips.vue"
export default{
    components:{
        StoreFooter,
        StoreButton,
        StoreInput,
        StoreButtonWrite,
        StoreTips
    },
    data(){
        return {
            tips:{
                show:false,
                text:""
            },
            logining:false,
            user:{
                username:"",
                password:""
            }
        }
    },
    mounted(){
        // console.log(this.$store);
        
    },
    methods:{
        checkLogin(){
            return this.$http.get("/user/check")
        },
        login(){
            if(!this.logining&&this.check()){
                this.logining = true;
                var that = this;
                this.$http.post("/user/login",this.user).then(function(e){
                    if(e.data==null){
                        // 登录失败
                        that.showTips("登录失败，用户名或密码错误");
                    }else{
                        // 登录成功
                        that.showTips("登录成功");
                        document.cookie = "token="+e.headers["token"];
                        that.globalData.setUser(e.data[0]);
                        setTimeout(function(){
                            that.$router.push("/");
                        },2000);
                    }
                }).finally(function(){
                    that.logining = false;
                });
            }
        },
        showTips(msg){
            this.tips.show = true;
            this.tips.text = msg;
        },
        check(){
            if(this.user.username.length <= 0){
                this.showTips("用户名不能为空");
                return false;
            }
            if(this.user.password.length <= 0){
                this.showTips("密码不能为空");
                return false;
            }
            if(!/^[A-Za-z0-9@]{8,16}$/.test(this.user.password)){
                this.showTips("密码不符合");
                return false;
            }
            return true;
        }
    },
    watch:{

    }
}
</script>
<style scoped>
.box .title{
    font-size: 40px;
    font-weight: bolder;
    text-align: center;
    padding:80px 0px;
}
.box .input{
    margin:15px 0px;
}
.box .buttons .button{
    margin:10px 0px;
}
.box .buttons{
    margin:65px 0px;
}
.box{
    padding:0px 15px;
    max-width:390px;
    margin:50px auto;
}
.footer{
    text-align: center;
    margin:50px 0px;
}
</style>