import { sha256 } from 'js-sha256';
export default function(app, config) {

    var data = function() {
        this.user = {
            user_name: "",
            user_id: -1
        }

        this.store = new store(config.store);
    }

    data.prototype.setUser = function(user) {
        this.user.user_id = user.user_id;
        this.user.user_name = user.user_name;
    };

    var store = function(api) {
        this.api = api;
        this.blockSize = 128 * 1024;
        this.count = 0;
    }

    store.prototype.getApi = function() {
        if (this.count >= this.api.length) {
            this.count = 0;
        }
        this.count += parseInt(Math.random() * this.api.length);
        return this.api[this.count % this.api.length];
    }

    store.prototype.request = function(method, path, responseType, timeout) {
        var xhr = new XMLHttpRequest();
        xhr.responseType = responseType;
        xhr.timeout = typeof(timeout) != "undefined" ? timeout : 8000;
        xhr.open(method.toUpperCase(), this.getApi() + path);
        return function(data) {
            xhr.send(data);
            return new Promise(function(ok, no) {
                xhr.onreadystatechange = function() {
                    if (xhr.readyState == 4) {
                        if (xhr.status >= 200 && xhr.status < 300) {
                            ok(xhr);
                        } else {
                            no(xhr);
                        }
                    }
                }
            });
        }
    }

    store.prototype.exist = function(blockIds) {
        return this.request("POST", "/block/exist", "text", 30000)(JSON.stringify(blockIds)).then(function(e) {
            return JSON.parse(e.responseText);
        }).catch(function() {
            return null;
        });
    }

    store.prototype.get = function(blockId) {
        return this.request("GET", "/block/get/" + blockId, "arraybuffer")("").then(function(e) {
            return e.response;
        }).catch(function() {
            return new ArrayBuffer(0);
        });
    }

    store.prototype.getText = function(blockId) {
        return this.request("GET", "/block/get/" + blockId, "text")("").then(function(e) {
            return e.responseText;
        }).catch(function() {
            return "";
        });
    }

    store.prototype.put = function(block) {
        return this.request("PUT", "/block/put", "text")(block).then(function(e) {
            return JSON.parse(e.responseText)["block_id"];
        }).catch(function() {
            return "";
        });
    }

    store.prototype.blockHash = function(data) {
        var factory = this.sha256.create();
        factory.update(data.slice(0, this.blockSize));
        factory.update(new ArrayBuffer(Math.max(this.blockSize - data.byteLength, 0)));
        return factory.hex();
    }

    store.prototype.sha256 = sha256;

    /*store.prototype.upload = function(arrayBuffer,cb){
        arrayBuffer.then(function(buffer){
            cb(0);
        });
    }

    store.prototype.uploadInit = function(buffer){
        var file = {
            size:buffer.byteLength,
            hash:sha256.create(),
            list:[],
            map:{},
            blockSize:this.blockSize,
            lastBlockSize:0
        };

        for(var i = 0;i * file.blockSize < file.size;i++){
            var data = buffer.slice(i*file.blockSize,(i+1)*file.blockSize);
            var hashValue = this.blockHash(data);
            file.hash.update(data);
            file.list.push(hashValue);
            file.map[hashValue] = data;
        }

        file.hash = file.hash.hex();
        if(file.list.length > 0){
            file.lastBlockSize = file.map[file.list[file.list.length - 1]].byteLength;
        }
        
        return file;
    }*/

    // methods.prototype.store = store;


    app.config.globalProperties.globalData = new data();
    // app.config.globalProperties.$store = new data();
}