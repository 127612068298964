var fileObject = function(store, events) {
    this.store = store;
    this.events = events;
    this.error = 0;
}

fileObject.prototype.ok = function() {
    return this.error < 5;
}
fileObject.prototype.download = function(indexs, thread) {
    if (this.ok()) {
        this.events["start"] && this.events["start"]();
        this.downloadIndex({ list: [], lastBlockSize: 0 }, indexs, 0, thread);
    }
}
fileObject.prototype.downloadIndex = function(file, indexs, idx, thread) {
    var that = this;
    if (indexs.length > idx) {
        var part = indexs[idx];
        this.store.getText(part.part_hash + ".bin").then(function(e) {
            if (e.length == that.store.blockSize) {
                e = e.slice(0, part.part_size);
                if (that.store.blockSize > part.part_size) {
                    file.lastBlockSize = parseInt(e.slice(e.length - (part.part_size % 64)));
                }
                file.list = file.list.concat(e.match(/.{64}/g));
                // 继续下一个
                that.downloadIndex(file, indexs, idx + 1, thread);
            } else {
                that.events["fail"] && that.events["fail"]();
            }
        });
    } else {
        that.events["beforeProcess"] && that.events["beforeProcess"](file);
        // 索引文件下载完成
        var info = {
            downloaded: 0,
            blocks: file.list.concat([])
        };
        for (var i = 0; i < thread; i++) {
            this.downloadFile(info, file, i, thread);
        }
    }
}
fileObject.prototype.downloadFile = function(info, file, idx, thread) {
    var that = this;
    if (!this.ok()) {
        return;
    }
    if (file.list.length > idx) {
        this.store.get(file.list[idx] + ".bin").then(function(e) {
            if (that.ok()) {
                if (e.byteLength == that.store.blockSize) {
                    that.error = 0;
                    info.downloaded += 1;
                    that.ok() && that.events["processing"] && that.events["processing"](info, file);
                    if (file.list.length - 1 == idx && file.lastBlockSize > 0) {
                        info.blocks[idx] = new Uint8Array(e).slice(0, file.lastBlockSize);
                    } else {
                        info.blocks[idx] = new Uint8Array(e);
                    }
                    if (info.downloaded == info.blocks.length) {
                        // 文件下载完毕
                        that.ok() && that.events["success"] && that.events["success"](new Blob(info.blocks));
                    } else {
                        that.downloadFile(info, file, idx + thread, thread);
                    }
                } else {
                    if (that.ok()) {
                        that.error += 1;
                        that.downloadFile(info, file, idx, thread);
                    }
                    if (!that.ok()) {
                        that.events["fail"] && that.events["fail"]();
                    }
                }
            }
        });
    }
}
fileObject.prototype.upload = function(arrayBuffer) {
    var that = this;
    arrayBuffer.then(function(buffer) {
        return that.uploadInit(buffer);
    }).then(function(file) {
        return that.events["beforeProccess"] ? that.events["beforeProccess"](file) : file;
    }).then(function(file) {
        if (file.skip) {
            that.events["success"] && that.events["success"](file);
        } else {
            that.uploadProcess(file);
        }
    }).catch(function() {
        that.events['fail'] && that.events['fail']();
    });
}
fileObject.prototype.uploadProcess = function(file) {

    var that = this;
    this.store.exist(file.list).then(function(e) {
        if (e == null) {
            that.events['fail'] && that.events['fail']();
        } else {
            var unupload = {
                list: [],
                map: {}
            };
            for (var i = 0; i < file.list.length; i++) {
                var hashValue = file.list[i];
                if (typeof(e[hashValue]) != "undefined" && e[hashValue] == false) {
                    unupload.list.push(hashValue);
                    unupload.map[hashValue] = file.map[hashValue];
                }
            }
            file.map = unupload.map;
            that.events['start'] && that.events['start'](file);

            that.uploadPart(file, unupload, 0);
        }
    });
}
fileObject.prototype.uploadPart = function(file, unupload, index) {
    if (unupload.list.length <= index) {
        this.events["success"] && this.events["success"](file);
    } else {
        var that = this;
        var hashValue = unupload.list[index];
        that.events['processing'] && that.events['processing'](file, unupload, index);
        this.store.put(unupload.map[hashValue]).then(function(blockId) {
            if (blockId !== hashValue) {
                // 上传失败
                that.error += 1;
                if (that.error < 5) {
                    that.uploadPart(file, unupload, index);
                } else {
                    that.events['fail'] && that.events['fail'](file);
                }
            } else {
                // 上传成功 继续下一个
                that.uploadPart(file, unupload, index + 1);
            }
        });
    }
}
fileObject.prototype.uploadInit = function(buffer) {
    var file = {
        skip: false,
        size: buffer.byteLength,
        hash: this.store.sha256.create(),
        list: [],
        map: {},
        blockSize: this.store.blockSize,
        lastBlockSize: 0
    };

    for (var i = 0; i * file.blockSize < file.size; i++) {
        var data = buffer.slice(i * file.blockSize, (i + 1) * file.blockSize);
        var hashValue = this.store.blockHash(data);
        file.hash.update(data);
        file.list.push(hashValue);
        file.map[hashValue] = data;
    }

    file.hash = file.hash.hex();
    if (file.list.length > 0) {
        file.lastBlockSize = file.map[file.list[file.list.length - 1]].byteLength;
    }

    this.events['init'] && this.events['init'](file);

    return file;
}

export default fileObject;