<template>
    <div class="store-footer">Copyright © {{new Date().getFullYear()}} {{name}}. 保留所有权利。</div>
</template>
<script>
export default {
    data(){
        return {
            
        }
    },
    props:{
        name:{type:String,default:"Romm Hui"}
    }
}
</script>
<style scoped>
.store-footer{
    font-size: 14px;
}
</style>