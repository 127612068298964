import { createApp } from 'vue'
import App from './App.vue'
import route from './route'
import http from './http'
import data from './data'
import axios from 'axios'

route.beforeResolve(function(to) {
    document.title = to.meta.title + " | StoreDisk";
});

axios.get("/config.json?" + (new Date().getTime())).then(function(e) {
    if (e.data.store && e.data.backend) {
        createApp(App).use(route).use(data, e.data).use(http, e.data).mount('#app')
    } else {
        throw "";
    }
}).catch(function() {
    alert("网站服务出现异常");
});