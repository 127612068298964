<template>
    <div class="store-loading">
        <div class="box">
            <div class="circle"></div>
            <div class="text">StoreDisk</div>
        </div>
    </div>
</template>
<script>
export default {
    data(){
        return {
            
        }
    }
}
</script>
<style scoped>
.box .text{
  font-size: 35px;
  font-weight: bolder;
  text-align: center;
  margin-top:50px;
}
@keyframes run {
  0%{
    transform: rotate(0deg);
  }
  100%{
    transform: rotate(360deg);
  }
}
.box .circle{
  border:5px solid #08001f;
  width: 50px;
  height: 50px;
  border-radius:50%;
  margin:20px auto;
  border-left-color: #ffffff;
  animation: run 0.7s infinite linear;
}
.box{
  max-width: 390px;
  margin:30vh auto;
}
</style>