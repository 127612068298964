<template>
  <div class="app">
    <StoreLoading v-if="loading"></StoreLoading>
    <router-view v-else></router-view>
    <StoreMediaPlayer :file="playFile" @close="closePlayer"></StoreMediaPlayer>
  </div>
</template>

<script>
import StoreLoading from "./pages/StoreLoading.vue";
import StoreMediaPlayer from "./components/StoreMediaPlayer.vue";
export default {
    name: "App",
    data() {
        return {
            loading: true,
            playFile:null,
            ts:(new Date().getTime())
        };
    },
    methods:{
      play(file){
        this.playFile = file;
      },
      closePlayer(){
        this.playFile = null;
      },
      check(){
        return this.$http.get("/user/check")
      },
      checkLogin(){
        var that = this;
        this.check().then(function(e){
          // that.$store.setUser(e.data);
          that.globalData.setUser(e.data);
          switch(that.$router.currentRoute.value.path){
            case "/login":
            case "/register":
              that.$router.push("/");
              break
          }
          that.loading = false;
        }).catch(function(){
          switch(that.$router.currentRoute.value.path){
            case "/login":
            case "/register":
              break
            default:
              that.globalData.user.user_id = -1;
              that.playFile = null;
              that.$router.push("/login");
          }
          that.loading = false;
        });
      },
      checkStatus(){
        var that = this;
        document.addEventListener("visibilitychange",function(){
          if(document.visibilityState == 'visible'){
            that.checkLogin();
          }
        });
        window.addEventListener("mousemove",function(){
          var ts = (new Date().getTime());
          if(ts > that.ts + 1800000){
            that.checkLogin();
            that.ts = ts;
          }
        });
      }
    },
    mounted() {
        this.checkLogin();
        this.checkStatus();
        // var that = this;
        // setInterval(function(){
        //   if(that.globalData.userId > 0){
        //     that.checkLogin();
        //   }
        // },1800000);
    },
    components: { StoreLoading,StoreMediaPlayer }
}
</script>
<style>
*{
  padding:0px;
  margin:0px;
  color:#08001f;
  font-family: "微软雅黑","黑体";
}
</style>