<template>
    <div class="store-login">
        <div class="login">
            <div class="box">
                <div class="title">StoreDisk 注册</div>
                <StoreInput class="input" v-model.trim="user.username">请输入用户名</StoreInput>
                <StoreInput class="input" v-model.trim="user.password" type="password">请输入密码</StoreInput>
                <StoreInput class="input" v-model.trim="user.confrim" type="password">请再次输入密码</StoreInput>
                <div class="buttons">
                    <StoreButton class="button" @click="register">注册</StoreButton>
                    <StoreButtonWrite class="button" @click="$router.push('/login')">已有账号，去登录</StoreButtonWrite>
                </div>
                <StoreFooter class="footer"></StoreFooter>
            </div>
        </div>
        <StoreTips v-model:show="tips.show">{{tips.text}}</StoreTips>
    </div>
</template>
<script>
import StoreFooter from "@/components/StoreFooter.vue"
import StoreButton from "@/components/StoreButton.vue"
import StoreInput from '@/components/StoreInput.vue'
import StoreButtonWrite from "@/components/StoreButtonWrite.vue"
import StoreTips from "@/components/StoreTips.vue"
export default{
    components:{
        StoreFooter,
        StoreButton,
        StoreInput,
        StoreButtonWrite,
        StoreTips
    },
    data(){
        return {
            tips:{
                show:false,
                text:""
            },
            registering:false,
            user:{
                username:"",
                password:"",
                confrim:""
            }
        }
    },
    mounted(){
        // console.log(this.$store);
    },
    methods:{
        register(){
            var that = this;
            if(!this.registering&&this.check()){
                this.registering = true;
                this.$http.post("/user/register",this.user).then(function(e){
                    that.globalData.setUser(e.data);
                    that.showTips("注册成功");
                    setTimeout(function(){
                        that.$router.push("/");
                    },1000);
                    that.registering = false;
                }).catch(function(){
                    that.showTips("注册失败");
                    that.registering = false;
                });
            }
        },
        showTips(msg){
            this.tips.show = true;
            this.tips.text = msg;
        },
        check:function(){
            if(this.user.username.length <= 0){
                this.tips.show = true;
                this.tips.text = "用户名不能为空";
                return false;
            }
            if(this.user.password.length <= 0){
                this.tips.show = true;
                this.tips.text = "密码不能为空";
                return false;
            }
            if(this.user.confrim.length <= 0){
                this.tips.show = true;
                this.tips.text = "确认密码不能为空";
                return false;
            }
            if(!/^[A-Za-z0-9@]{8,16}$/.test(this.user.password)){
                this.tips.show = true;
                this.tips.text = "密码不符合";
                return false;
            }
            if(this.user.confrim !== this.user.password){
                this.tips.show = true;
                this.tips.text = "密码和确认密码不一致";
                return false;
            }
            return true;
        }
    },
    watch:{

    }
}
</script>
<style scoped>
.box .title{
    font-size: 40px;
    font-weight: bolder;
    text-align: center;
    padding:80px 0px;
}
.box .input{
    margin:15px 0px;
}
.box .buttons .button{
    margin:10px 0px;
}
.box .buttons{
    margin:65px 0px;
}
.box{
    padding:0px 15px;
    max-width:390px;
    margin:50px auto;
}
.footer{
    text-align: center;
    margin:50px 0px;
}
</style>