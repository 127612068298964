<template>
    <div class="store-button"><slot></slot></div>
</template>
<script>
export default {
    data(){
        return {
            
        }
    }
}
</script>
<style scoped>
.store-button:active{
    background-color: #211e2b;
}
.store-button{
    user-select: none;
    background-color:#08001f;
    color:#f0f0f0;
    padding:15px 20px;
    text-align: center;
}
</style>