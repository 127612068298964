<template>
    <div class="store-user">
        <div class="header">
            <router-link class="name" to="/">StoreDisk</router-link>
            <div class="action">
                <router-link class="username" to="/user">{{globalData.user.user_name}}</router-link>
                <div class="placeholder"></div>
            </div>
        </div>
        <div class="body">
            <h2 class="name">
                <div class="content">用户中心</div>
            </h2>
            <div class="list">
                <div v-for="(item,idx) in list"  :key="item.name" :class="{'part':true,'open':openIdx == idx}">
                    <div class="name" @click="open(idx)">{{item.name}}</div>
                    <div class="content">
                        <form>
                            <template v-for="inp in item.inputs" :key="inp.name">
                                <label :for="inp.data">{{inp.name}}</label>
                                <StoreInput :id="inp.data" :type="inp.type" v-model.trim="item.data[inp.data]">{{inp.name}}</StoreInput>
                            </template>
                        </form>
                        <StoreButton class="btn" @click="submit(item)">{{item.name}}</StoreButton>
                    </div>
                </div>
                <StoreFooter class="footer"></StoreFooter>
            </div>
        </div>
        <StoreTips v-model:show="tips.show">{{tips.text}}</StoreTips>
    </div>
</template>
<script>
import StoreTips from "@/components/StoreTips.vue";
import StoreInput from "@/components/StoreInput.vue";
import StoreFooter from "@/components/StoreFooter.vue";
import StoreButton from "@/components/StoreButton.vue";
export default {
    components: { StoreFooter, StoreInput, StoreButton,StoreTips },
    data(){
        return {
            tips:{
                show:false,
                text:""
            },
            openIdx:0,
            list:[
                {
                    api:"/user/name/update",
                    name:"修改用户名",
                    inputs:[
                        {
                            name:"用户名",
                            data:"user_name",
                            type:"text"
                        }
                    ],
                    data:this.globalData.user,
                    check(data){
                        if(data.user_name.length <= 0){
                            this.showTips("用户名不能为空");
                            return false;
                        }
                        return true;
                    },
                    success(data){
                        this.globalData.user.user_name = data.user_name;
                        window.location.reload();
                    }
                },
                {
                    api:"/user/password/update",
                    name:"修改密码",
                    inputs:[
                        {
                            name:"旧密码",
                            data:"password",
                            type:"password"
                        },
                        {
                            name:"新密码",
                            data:"new_password",
                            type:"password"
                        },
                        {
                            name:"重复新密码",
                            data:"repeat_password",
                            type:"password"
                        }
                    ],
                    data:{
                        password:"",
                        new_password:"",
                        repeat_password:""
                    },
                    check(data){
                        if(data.new_password.length <= 0){
                            this.showTips("密码不能为空");
                            return false;
                        }
                        if(data.repeat_password.length <= 0){
                            this.showTips("确认密码不能为空");
                            return false;
                        }
                        if(!/^[A-Za-z0-9@]{8,16}$/.test(data.new_password)){
                            this.showTips("密码不符合");
                            return false;
                        }
                        if(data.repeat_password !== data.new_password){
                            this.showTips("密码和确认密码不一致");
                            return false;
                        }
                        return true;
                    },
                    success(){
                        window.location.reload();
                    }
                },
                {
                    api:"",
                    name:"退出登录",
                    inputs:[],
                    data:{},
                    check(){
                        return confirm("是否真的退出?");
                    },
                    success(){
                        document.cookie = "token=";
                        window.location.reload();
                    }
                }
            ]
        }
    },
    methods:{
        open(idx){
            this.openIdx = idx;
        },
        submit(item){
            var that = this;
            if(item.check.apply(this,[item.data])){
                if(item.api.length > 0){
                    this.$http.post(item.api,item.data).then(function(e){
                        if((typeof(e.data.status)!="undefined" && e.data.status)||(typeof(e.data.user_id)!="undefined" && e.data.user_id > 0)){
                            item.success.apply(that,[e.data]);
                            that.showTips(item.name+"成功");
                        }else{
                            that.showTips(item.name+"失败");
                        }
                    });
                }else{
                    item.success.apply(that,[item.data]);
                    that.showTips(item.name+"成功");
                }
            }
        },
        showTips(msg){
            this.tips.show = true;
            this.tips.text = msg;
        }
    }
}
</script>
<style scoped>
.header .name{
    font-size: 30px;
    font-weight: bolder;
    margin:auto 0px;
    text-decoration: none;
}
.header .action .placeholder{
    height: 45px;
}
.header .action .username{
    margin:auto 20px;
    font-weight: bolder;
}
.header .action{
    display:flex;
    justify-content: space-between;
}
.header{
    display:flex;
    justify-content: space-between;
    padding:10px 15px;
    box-shadow: 0px 3px 10px -11px #08001f;
    position:fixed;
    top:0px;
    width:calc(100% - 30px);
    background-color: #ffffff;
}
.store-user .body .list .part.open .content{
    height: auto;
    max-height: 1000px;
    padding:20px 20px;
}
.store-user .body .list .part.open .name::after{
    transform: rotate(90deg);
}
.store-user .body .list .part .content label{
    padding:10px 5px;
    display: block;
}
.store-user .body .list .part .content .btn{
    margin-top:40px;
}
.store-user .body .list .part .content{
    height: 0px;
    max-height: 0px;
    overflow: hidden;
    max-width: 400px;
    margin:0px auto;
    transition: max-height 1.5s;
}
.store-user .body .list .part .name::after{
    content:"";
    padding: 10px 10px;
    background-image: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAEAAAABACAYAAACqaXHeAAAAAXNSR0IArs4c6QAAAXJJREFUeF7t2zsOwjAQRdFxB5tAbAoWmeyKTdAR5CJSigg54/m855AaGd2TTzOaIgbXRe5Tkc/8ltdscFzoEaX332q8yPKo5xRZnmwIXQDb+BWSDUENsBfPiKAC+BXPhnAYoCWeCeEwwFVuj0XK1PrxRP8mHAao4SMhqABGQlADjILQBTACQjcAO4IJADOCGQArgikAI4I5ABuCCwATghsAC4IrAAOCOwA6QggAMkIYACpCKAAiQjgAGkIKABJCGgAKQioAAkI6QDYCBEAmAgxAFgIUQAYCHEA0wh+gdcQV9bvoqRPUExAdX28qDEBGPAxAVjwEQGZ8OkB2fCoAQnwaAEp8CgBSfDgAWnwoAGJ8GABqfAgAcrw7AHq8KwBDvBsAS7wLAFO8OQBbvCkAY7wZAGu8CQBzfDcAe3wXwAjxaoBR4lUAI8WrAE6/NFXVWhDQl6XWSZd6MHLqxclV79Srs3sILI/9dtCrfgW2hzCvz38BdM9+UB8+K5QAAAAASUVORK5CYII=);
    background-size: 50% 50%;
    background-repeat: no-repeat;
    background-position: center;
    float: right;
}
.store-user .body .list .part .name{
    padding:20px 20px;
    border-bottom:1px solid #f0f0f0;
    background-color: #ffffff;
    color:#08001f;
    font-weight: bolder;
}
.store-user .body .list{
    max-width:800px;
    margin:0px auto;
}
.store-user .body .name .content{
    max-width:800px;
    color:#f0f0f0;
    margin:0px auto;
    padding-left:20px;
}
.store-user .body .name{
    padding:40px 0px;
    background-color:#08001f;
}
.store-user .body .footer{
    text-align: center;
    padding:30px 0px;
}
.store-user{
    padding-top:65px;
}
</style>