<template>
    <div class="store-tips" v-show="show" @mouseover="clearTimer()" @mouseout="setTimer()">
        <slot></slot>
        <div class="close" @click="close()">×</div>
    </div>
</template>
<script>
export default {
    data(){
        return {
            timer:null
        }
    },
    props:{
        show:{type:Boolean,default:false},
        timeout:{type:Number,default:2500}
    },
    methods:{
        close(){
            this.clearTimer();
            this.$emit("update:show",false);
        },
        clearTimer:function(){
            clearTimeout(this.timer);
        },
        setTimer:function(){
            var that = this;
            this.timer = setTimeout(function(){
                setTimeout(function(){
                    that.close();
                },500);
            },this.timeout);
        }
    },
    watch:{
        show(nv){
            if(nv){
                this.setTimer();
            }
        }
    }
}
</script>
<style scoped>
.store-tips .close{
    color:#ffffff;
    font-size: 25px;
    user-select: none;
}
.store-tips{
    transition:opacity 0.5s ease;
    width:85%;
    max-width:400px;
    background-color:#08001f;
    color:#ffffff;
    padding:15px 20px;
    margin:5px auto;
    font-size: 16px;
    line-height: 33px;
    display: flex;
    justify-content: space-between;
    position: fixed;
    left:0%;
    right:0%;
    top:10px;
    box-shadow: 0px 3px 10px -6px #08001f;
}
</style>