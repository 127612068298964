<template>
    <input :type="type" :value="modelValue" @input="$emit('update:modelValue',$event.target.value)" class="store-input" :placeholder="$slots.default()[0].children">
</template>
<script>
export default {
    data(){
        return {
            
        }
    },
    props:{
        type:{type:String,default:"text"},
        modelValue:{type:String,default:""}
    }
}
</script>
<style scoped>
.store-input{
    font-size: 18px;
    border:1px solid #08001f;
    padding:14px 15px;
    width:calc(100% - 34px);
}
</style>