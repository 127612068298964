import { createRouter, createWebHistory } from 'vue-router'

import StoreLogin from './pages/StoreLogin.vue'
import StoreRegister from './pages/StoreRegister.vue'
import StoreIndex from './pages/StoreIndex.vue'
import StoreUser from './pages/StoreUser.vue'

export default createRouter({
    history: createWebHistory(),
    routes: [{
            path: "/login",
            component: StoreLogin,
            meta: { title: "用户登录" }
        },
        {
            path: "/register",
            component: StoreRegister,
            meta: { title: "用户注册" }
        },
        {
            path: "/",
            component: StoreIndex,
            meta: { title: "文件列表" }
        },
        {
            path: "/user",
            component: StoreUser,
            meta: { title: "用户中心" }
        }
    ]
})