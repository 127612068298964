<template>
    <div class="store-index">
        <div class="header">
            <div class="name">StoreDisk</div>
            <div class="action">
                <router-link class="username" to="/user">{{globalData.user.user_name}}</router-link>
                <StoreFileInput @change="upload">＋</StoreFileInput>
            </div>
        </div>
        <div class="list-box" @scroll="update" @dragleave="prevent" @drag="prevent" @dragover="prevent" @dragenter="prevent" @dragend="prevent" @dragstart="prevent" @drop="dragFile">
            <div v-if="false" class="search-box">
                <div class="container">
                    <div class="search">
                        <StoreInput>搜索文件</StoreInput>
                        <StoreButtonWrite class="btn">搜索</StoreButtonWrite>
                    </div>
                </div>
            </div>
            <div class="container">
                <div v-if="!canLoad && list.length == 0 && !isloading" class="message">
                    {{message}}
                </div>
                <div v-else class="list">
                    <StoreUploadFileItem @click:name="play" class="item" v-for="(file,idx) in list" :index="idx" :file="file" :key="file" @uploaded="uploaded" :remove="remove" :rename="rename" :del="deleteFile"></StoreUploadFileItem>
                    <!-- <StoreDownloadFileItem class="item" v-for="(file,idx) in list" :file="file" :key="idx"></StoreDownloadFileItem> -->
                </div>
                <div class="loading" v-show="isloading">正在加载...</div>
            </div>
        </div>
        <!-- <StoreMusicPlayer :file="playFile" @close="closePlayer"></StoreMusicPlayer> -->
        <!-- <StoreModal title="Hello World" text="你好了?"></StoreModal> -->
    </div>
</template>
<script>
import StoreFileInput from "@/components/StoreFileInput.vue";
import StoreUploadFileItem from "../components/StoreUploadFileItem.vue";
import StoreInput from "@/components/StoreInput.vue";
// import StoreButton from "@/components/StoreButton.vue";
import StoreButtonWrite from "@/components/StoreButtonWrite.vue";
// import StoreMusicPlayer from "@/components/StoreMusicPlayer.vue";
// import StoreModal from "@/components/StoreModal.vue";
export default {
    data(){
        return {
            list:[],
            // uploadList:[],
            isloading:false,
            canLoad:true,
            message:"文件列表为空",
            playFile:null
        }
    },
    methods:{
        prevent(e){
            // return false;
            e.preventDefault();
        },
        dragFile(e){
            this.prevent(e);
            this.upload(e.dataTransfer);
        },
        // closePlayer(){
        //     this.playFile = null;
        // },
        play(file){
            this.$root.play(file);
            // this.playFile = file;
        },
        remove(index){
            this.list.splice(index,1);
        },
        rename(index,file){
            var new_name = prompt("是否要重命名?",file.name);
            if(new_name!=null&&new_name!==file.name){
                // var that = this;
                this.$http.post("/file/rename",{
                    id:file.id,
                    new_name:new_name
                }).then(function(e){
                    if(e.data.status==true){
                        file.name = new_name;
                    }
                });
            }
        },
        deleteFile(index,file){
            var that = this;
            if(confirm("是否要删除该文件?")){
                this.$http.get("/file/delete/"+file.id).then(function(e){
                    if(e.data.status==true){
                        that.list.splice(index,1);
                    }
                });
            }
        },
        upload:function(e){
            var list = [];
            for(var i = 0;i<e.files.length;i++){
                var file = e.files[i];
                list.push(file);
            }
            list = list.concat(this.list.splice(0));
            for(i = 0;i<list.length;i++){
                this.list.push(list[i]);
            }
            e.value = "";
        },
        uploaded:function(idx,file){
            // console.log(file,uploadfile);
            this.list[idx] = file;
            /*this.list = [file].concat(this.list);
            for(var i = 0;i<this.uploadList.length;i++){
                if(this.uploadList[i]==uploadfile){
                    this.uploadList.splice(i,1);
                    break;
                }
            }*/
        },
        getList(offset,count){
            var that = this;
            that.canLoad = false;
            that.isloading = true;
            this.$http.get("/file/list/"+offset+"/"+count).then(function(e){
                that.isloading = false;
                for(var i = 0;i<e.data.length;i++){
                    that.list.push(e.data[i]);
                }
                if(e.data.length < count){
                    that.canLoad = false;
                }else{
                    that.canLoad = true;
                }
            }).finally(function(){
                that.isloading = false;
            });
        },
        update(e){
            if(this.canLoad){
                var target = e.target;
                if(target.scrollHeight - (target.scrollTop + target.clientHeight) < 30){
                    this.getList(this.list.length,15);
                }
            }
        }
    },
    mounted:function(){
        this.getList(this.list.length,15);
    },
    components:{ StoreFileInput, StoreUploadFileItem, StoreInput, StoreButtonWrite }
}
</script>
<style scoped>
.header .name{
    font-size: 30px;
    font-weight: bolder;
    margin:auto 0px;
}
.header .action .username{
    margin:auto 20px;
    font-weight: bolder;
}
.header .action{
    display:flex;
    justify-content: space-between;
}
.header{
    display:flex;
    justify-content: space-between;
    padding:10px 15px;
    box-shadow: 0px 3px 10px -11px #08001f;
    position:fixed;
    top:0px;
    width:calc(100% - 30px);
    background-color: #ffffff;
}
.container .list .item:nth-last-child(1){
    border-bottom: none;
}
.container .list .item{
    border-bottom:1px solid #f0f0f0;
}
.container .list{
    padding:15px 10px;
}
.list-box{
    height:calc(100vh - 165px);
    overflow: hidden;
    overflow-y: auto;
    padding-bottom: 100px;
}
.container .message{
    margin-top:45%;
    font-size: 20px;
    font-weight: bolder;
    text-align: center;
}
.container{
    max-width: 900px;
    margin:0px auto;
}
.search-box .search .btn{
    width:100px;
}
.search-box .search{
    padding:0px 10px;
    display:flex;
}
.search-box{
    /* background-image: url(http://what.tansoz.com/https://images.pexels.com/photos/11866150/pexels-photo-11866150.jpeg?auto=compress&cs=tinysrgb&dpr=2&h=4000&w=5000); */
    background-repeat: no-repeat;
    background-size: 100%;
    background-position-x: center;
    background-color: #08001f;
    padding:28px 0px;
}
.loading{
    text-align: center;
    padding:30px 0px;
    font-size: 20px;
}
.store-index{
    padding-top:65px;
}
</style>