<template>
    <div class="store-file-input">
        <input @change="$emit('change',$event.target)" multiple type="file" class="input">
        <div class="text"><slot></slot></div>
    </div>
</template>
<script>
export default {
    data(){
        return {
            
        }
    },
    emits:["change"]
}
</script>
<style scoped>
.store-file-input:active{
    background-color: #211e2b;
}
.store-file-input .text{
    margin:auto 0px;
    color:#f0f0f0;
    margin-top:-45px;
    width:45px;
    height:45px;
    line-height: 45px;
    font-size: 30px;
}
.store-file-input .input{
    width: 45px;
    height:45px;
    opacity: 0;
}
.store-file-input{
    user-select: none;
    background-color:#08001f;
    text-align: center;
}
</style>