<template>
    <div v-show="show && file != null" :class="{'store-media-player':true,'isVideo':isVideo,'fullscreen':fullscreen}">
        <video :src="src" @timeupdate="update" @canplay="canplay" class="player" @mousedown="play" @waiting="waiting" @playing="p" @ended="ended"></video>
        <div class="panel">
            <div class="controller">
                <div @click="play" :class="{'play-btn':true,'playing':playing,'btn':true}"></div>
                <div class="time">{{now}} / {{duration}}</div>
                <div class="bar" @mousedown="seek">
                    <div class="loaded" v-for="item in loaded" :key="item" :style="{'width':item.width,'left':item.offset}"></div>
                    <div class="played" :style="{'width':barWidth}"></div>
                </div>
                <div :class="{'full':!fullscreen,'unfull':fullscreen,'btn':true}" v-if="isVideo" @click="fullOrNotFull"></div>
                <div class="close btn" @click="close"></div>
            </div>
            <div class="info" v-if="file != null">{{message}}: {{file.name}}</div>
        </div>
    </div>
</template>
<script>
export default {
    data(){
        return {
            message:"获取文件地址",
            now:"00:00",
            duration:"00:00",
            show:false,
            src:"",
            playing:false,
            player:null,
            can:false,
            barWidth:0,
            isVideo:false,
            fullscreen:false,
            loaded:[]
        }
    },
    emits:["close"],
    props:{
        file:{type:Object,default:()=>({name:"",size:0,id:-1})}
    },
    mounted(){
        this.listener();
        this.key();
    },
    watch:{
        file(f){
            this.init();
            if(f!=null){
                if(!this.checkCanPlay(f)){
                    return;
                }
                var that = this;
                this.show = true;
                this.message = "获取文件地址";
                if(f instanceof File){
                    this.src = URL.createObjectURL(f);
                }else{
                    this.getResourceURL(f).then(function(e){
                        if(e!=""){
                            var url = that.$http.api + "/file/get?" + e;
                            that.src = url;
                        }
                    });
                }
            }
        }
    },
    methods:{
        key(){
            var that = this;
            window.addEventListener("keydown",function(e){
                // console.log([that.player]);
                switch(e.key){
                
                    case ' ':
                        e.preventDefault();
                        that.play();
                        break;
                    case 'ArrowRight':
                        e.preventDefault();
                        that.player.currentTime += 5;
                        break;
                    case 'ArrowLeft':
                        e.preventDefault();
                        that.player.currentTime -= 5;
                        break;
                    case 'ArrowUp':
                        e.preventDefault();
                        that.player.volume = Math.min(that.player.volume + 0.1,1);
                        break;
                    case 'ArrowDown':
                        e.preventDefault();
                        that.player.volume = Math.max(that.player.volume - 0.1,0);
                        break;

                }
            });
        },
        listener(){
            var that = this;
            document.addEventListener("fullscreenchange",function(){
                that.fullscreen = that.isFullScreen();
            });
        },
        isFullScreen(){
            return (document.webkitIsFullScreen || document.msIsFullScreen || document.mozIsFullScreen);
        },
        isMobile(){
            return window.clientInformation.userAgent.match(/mobile/i);
        },
        fullOrNotFull(){
            if(this.isFullScreen()){
                this.unfull();
            }else{
                this.full();
            }
        },
        full(){
            var e = document.documentElement;
            var that = this;
            (e.requestFullscreen || e.msRequestFullscreen || e.mozRequestFullScreen || e.webkitRequestFullScreen).apply(e);
            that.isMobile() && window.screen.orientation.lock('landscape-primary');
        },
        unfull(){
            var e = document;
            (e.cancelFullScreen || e.msCancelFullScreen || e.mozCancelFullScreen || e.webkitCancelFullScreen).apply(e);
        },
        p(){
            this.message = '正在播放';
        },
        waiting(){
            this.message = "正在加载";
        },
        init(){
            this.message = "获取文件地址";
            this.now = "00:00";
            this.duration = "00:00";
            this.show = false;
            this.src = "";
            this.playing = false;
            this.can = false;
            this.barWidth = 0;
            this.isVideo = false;
            this.loaded = [];
        },
        ended(){
            this.message = '播放结束';
            this.playing = false;
        },
        seek(e){
            var offset = e.offsetX;
            if(e.target!=e.currentTarget){
                offset += e.target.offsetLeft;
            }
            if(this.can){
                this.barWidth = (offset * 100 / e.currentTarget.clientWidth)+'%';
                this.player.currentTime = (offset / e.currentTarget.clientWidth) * this.player.duration;
            }
        },
        canplay(e){
            this.can = true;
            this.player = e.target;
            this.player.title = this.file.name;
            if(!this.playing){
                this.message = '可以播放';
                this.duration = this.formatTime(e.target.duration);
                if(this.player.videoHeight>0){
                    this.isVideo = true;
                }
            }
        },
        update(e){
            var target = e.target;
            // console.log(target.buffered);
            var buffered = target.buffered;
            this.loaded.splice(0); // 全部清除
            for(var i = 0;i<buffered.length;i++){
                var start = buffered.start(i);
                var end = buffered.end(i);
                this.loaded.push({
                    width:((end - start) * 100 / target.duration)+'%',
                    offset:(start * 100 / target.duration)+'%'
                });
            }
            if(!isNaN(target.currentTime)&&!isNaN(target.duration)){
                this.now = this.formatTime(target.currentTime);
                this.duration = this.formatTime(target.duration);
                this.barWidth = (target.currentTime * 100 / target.duration)+'%';
            }
            
        },
        play(){
            if(this.can){
                this.playing = !this.playing;
                if(this.playing){
                    this.player.play();
                }else{
                    this.player.pause();
                    this.message = '暂停播放';
                }
            }
        },
        close(){
            this.show = false;
            this.isFullScreen() && this.unfull();
            this.$emit('close',this.src);
        },
        checkCanPlay(file){
            return file.name.match(/\.((mp3)|(flac)|(mp4)|(wav)|(m4a))$/i) != null;
        },
        getResourceURL(file){
            return this.$http.get("/file/url/"+file.id).then(function(e){
                return e.data.query;
            });
        },
        formatTime(sec){
            var min = parseInt(sec / 60);
            sec = parseInt(sec % 60);
            return (min > 9 ? '':'0')+min + ":" + (sec > 9 ? '':'0')+sec;
        }
    }
}
</script>
<style scoped>
.store-media-player .controller .bar .loaded{
    background-color: #b1b1b2;
    height:100%;
    position: absolute;
    bottom: 0px;
}
.store-media-player .controller .bar .played{
    background-color: #08001f;
    height:100%;
    bottom: 0px;
    position: absolute;
}
.store-media-player.isVideo .controller .bar{
    width:calc(100% - 265px);
}
.store-media-player .controller .bar{
    background: #c4c4c4;
    width:calc(100% - 215px);
    height:12px;
    margin:auto 10px;
    position: relative;
}
.store-media-player .controller .btn:active{
    background-color: #c0c0c0;
}
.store-media-player .controller .play-btn.playing{
    background-image:url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAEAAAABACAYAAACqaXHeAAAAAXNSR0IArs4c6QAAASFJREFUeF7tmUsKwkAQBTuKkFsEd7ryOh7R67jSneQWAfGD4CIJ2U1NYJjKAV53ql/3C6SJyp+m8vcPAeiAygm4ApUbwCPoCrgClRNwBSo3gCngCrgCMIFd7E/beF9+sq/YnJ/xuKaUoPXmveAr0EZ3i4jDv9B9iP6YAoDWWwPAZ1xkiD4JchsdqieAGYGk6SxZm54YracDdMCUgCuQElHegAUC9NGi9TyCHkGP4ISAKWAKwAToq03rmQKmgClgCowJGINwCAR9tWk9U8AUMAVMAVNgRMAYNAZhAnRu03prfAfU/XOU/p1N62V3ALxR2eXwFMjeMVxAADDQ4uR0QHEjgxvWATDQ4uR0QHEjgxvWATDQ4uR0QHEjgxvWATDQ4uS+fCDQQfyQCDUAAAAASUVORK5CYII=);
}
.store-media-player .controller .play-btn{
    background-image:url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAEAAAABACAYAAACqaXHeAAAAAXNSR0IArs4c6QAAAlBJREFUeF7tmkFr1EAUx39vEapXwaMKFT35wfwWepmA4EEQwYMHBUFED0IRPAh6EBS8CCqIVs1ut1gqSkFqsku7I7PNai+lzc4km+TNXnLYmZf//zfzksnME5T/RLl/IoA4A5QTiCmgfALEh2BMgRApsMTpcy7OiLUvIeLVGcN7BhznzBWQy4XoexPEjEk/1mnC514BAJz9CZzcJ+KHIElGet1HWF19QwCwB4h9CtbkDF7WZWae+1QJwOlxcEzObgLDbB6BVfepGkChX970IPlD+rhqQ2Xj1wRgJsveEnZNxvqwrNCq2tcMYGrjG5Dk9G9XZapM3EUAmOqz8OgYYrZJ35YRHLrtwgAURn4L1mQMroY2dtR4iwYw0/kcbJIzeHZU4aHaNQXALC2unUDMFulWKIOHxWkUACdWkHdu7ZCRPjhMfIj/Gwfgvyl7xzIxI4arIYweFKPBAKaSv1swI/o3q4LQdABT34KsuLVDRvo6NIhWAChMj/feFMsGXuyEAtEmADPPr4q0eBICQhsBFGnBjR6SbJNu+IBoLYA90/Yz9ExOendeCC0H8M/2/QmTZMza+7IgugLA+f4l2EsZg4dlIHQJgPO9mtM/rxnAh5z+Ra0AVKeA1oeg4tegoHchpHYprPdjSPPnsOYNEaVbYqo3RS2o3RZXezCi92hM8+Go5uNxtQUSektkVBdJqSmT2wRO7duH01YoqbxU1o38EssX3HXE109ldmSb0Nb7XKAJJnw0RAA+9LrQN86ALoyij4c4A3zodaFvnAFdGEUfD38B9EEKUEKYMMcAAAAASUVORK5CYII=);
    background-size: 25px 25px;
    background-position: center;
    background-repeat: no-repeat;
    width: 50px;
    height: 50px;
}
.store-media-player .controller .time{
    margin:auto 0px;
    font-size: 14px;
}
.store-media-player .controller{
    display: flex;
    justify-content: space-between;
}
.store-media-player.isVideo.fullscreen .player{
    height:100vh;
}
.store-media-player.isVideo .player{
    width:100%;
    height:calc(100vh - 89px);
}
.store-media-player .player{
    width:0px;
    height:0px;
    display: block;
    background-color: #000000;
}
.store-media-player .unfull{
    background-image: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAEAAAABACAYAAACqaXHeAAAAAXNSR0IArs4c6QAAAnVJREFUeF7tmjtSwzAQhn9lUsSXCIFb0NHRpGNIcgw4CTkGj6GjoaOCW0DIJZyCiRiRUWws25LWSuyx1q2ttfbbf/VcgcgfEbn/YACsgMgJcApELgAeBDkFOAUiJ8Ap0FAAwwSTqxSrh4Z2SM0TTOYpVs8AfkgGgEazwHCE8QsgLgXE4tgQlPMS8h6Qrxusp1QI1BTYO6/JFyEkGF+XRSXF+qkuWi7tMue1JToEAoCL4Qiff5EvOpKHoByREI/Fbzb4rv3nCCfStCtnGpzpfB7C2RR480oHbwAJxjMJUZnzGsIhAFQ7v4MgIOcp1gb0OsV5A1DGXCAA221IBQCDwS7nyx+K8ztoxMcBwlJC3oRJAVFqKxt//COftSUCcFFCmWnKGFAvYbrzjRSgO2VTQggFhJZ93h45BfJGfCCEUgA1580ZhpgC5nwtziVwazMXAoAA7gD5kf+XbX1RrSJbjyveV01zNnMhAJhRzNYJtv8HVUDZNGfrAAMgrARtUAVYAfulsg0Wp0CBQJBp0Jd6l75nAF2KRht9YQW0Qb1L/2QFdCkabfSFFUClzpuhilNfG1DeDPFmyP9ewKYq3g22tR3+H5ljHonJJYD3Vo/Eoj8U1QASnM4kts7X4yFnAYHBPMWX11VYsAMRZcjXedUmJABlrykE8krQflFZfp1FAaCOweuO3JvUJ5AAODi/CH85CuFyK22bMhungIvzqlrkMNfj9eMNRQkUBRjVIZrqcQokqiDQqkQoAJS/MZfI7LMo6iIpTSHqMjnfAbeT31PHgE46Q+kUA6BQ61MbVkCfoknxhRVAodanNqyAPkWT4gsrgEKtT21+AQJQgVDZkpVQAAAAAElFTkSuQmCC);
    background-size: 50% 50%;
}
.store-media-player .full{
    background-image: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAEAAAABACAYAAACqaXHeAAAAAXNSR0IArs4c6QAAAqNJREFUeF7tmk1OwzAQhZ+RQO0FYAOUFWeAewDlLsAKuAt/94AzICQosAAu0AokjNw2KAQ7M3ZIcOLpthPb7/Ob8U+ikPhPJa4fAkAckDgBSYHEDSBFUFJAUiBxApICiRsgbBXoYfBUALcI4ANQ12VAJ3jY4wDvYeOiPE5vA1gC8J6Pm2C0xmk/HxOUAnMAq36dqUs/AHrXr308CwBxQHMp8AJgxc+itafA2wQjzzGhUhGkasANoH4US78akMerTXHbIoA3WQNMlaaLlIIejvFIVPRyWX2s72moc9ptfIf9wSrAA2A6qgKBL37aE3uVaRRAKAQ/8XEAuCnLUx8nMMRb+vp3B6hLhc+rsnzlQKDEmzY0FnZ+16AIAJgqzxHgKozcZ2db5WIRjgSAyUZCyNEEoxNbVe9hcAjg2PZf3j2RAPg9zPw674DgFJ+1ZoNQTB3XYYm7z6i8CtBr8iyiAIEUb4PAqRvc8didVeVpxrNzCJsu27uaME5Q0LdVN1LUEIOOw1SjbfpfALRptuoYqzigDqptalMcUPds9bG+q6HMMnjq01cPgwMFdTfGA+MuwKfln7G1OmAuPrsQOeRCMOIBTLfLCmq/TghBADhb0YL4DDsJIS8+e0hhYTjG/ffNEqd/ricqAHCfxhziSQg28TYIkRyG7ADoI63b0n1sDDX0mWv2snNBtADoCxE6nzkQIr0QAXElRovPZp6CANj6+vcLEXfZCankDAiFDiMFECLewwk5CBECqCLeH0KjAKbfB6T8amzwCmCZu9mYxfFnyL7Mkb1F93I0uEgFAmjy5SgrBQRA4dzFfnnZFgfk9c0/WErkIymyHLUoIOg02CJ95FAFAImo4wHigI5PMClPHEAi6niAOKDjE0zKEweQiDoekLwDvgAoZIhQR46IBgAAAABJRU5ErkJggg==);
    background-size: 50% 50%;
}
.store-media-player .btn{
    width:50px;
    height:50px;
    background-repeat: no-repeat;
    background-position: center;
}
.store-media-player .close{
    width:50px;
    height:50px;
    background-image: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAEAAAABACAYAAACqaXHeAAAAAXNSR0IArs4c6QAAAvBJREFUeF7tmv2HFlEUx79LIiKWRERE+mVFLBGJiIhEm4hERGSJZUlsRSIiEhGRiHbrf8yX+3B33TPm3vMyw9z5+Xlm7ucz576cc2YNC7/WFs6PLqBHwMIN9Cmw8ADoi2CfAn0KLNxAnwILDwCTXWArSTwIlnkJwEMAbzTP1U4Bwu+nATwAECXhIoC/ADYAvNVI0AjI4VcvIULCBQD/AFzO3nyzhFYBJfgICecT/JVC2DdJ8BDAsXlEwrkEvynM+XcA9mrXg1YBfM5QFFhLOJvgrwqA7wG8roXn7zUCoiScSfDXBMAPAF61wFsI8JZwOq321wXAjwB2W+GtBHhJWE9v/oYA+AnAjgbeUoC1hFMJ/qYA+BnASy28tQArCScT/C0B8AuAbQt4DwFaCScS/G0B8CuAF1bwXgJaJRxP8HcEwG8AnlvCewqolXAsrfZ3BcDvAJ5Zw3sLGCuBSQ3P9vcEwB8AnnrARwgYI4EC7guAPwE88YKPEjBGQonxF4DHnvCRAmol/AbwyBs+WsBYCX9SpSeCX50M1Q6SyRcrSNKc5/08UmlxnNpssEYAtzrCS6t9fq8wCVECeMghvLTPl0SGSIgQwOMt4aUT3lAUuUvwFsDEhvDS2Z4LHg9Bq8pyeCR4CmBKSzApq8u3usjy2iHJXgJYzCC8lM+XDjmTSPAQwDIW4aVKztDxNlyCtQAWMAkv1fDGJDahEiwFsHRNeKl6W5PShkmwEsCmBeGlun1LMSNEgoUAtqsIL3VsNGUsdwlaAWxUEr7Uq+N2Y1HAdJWgEcAWNeHzLm2+x5qVrj3bcK0C+HEC4dmfL10mTYsjN3aJhFYB/CpD6sSq21UDycGQBH6cwdyh6moVwIeUJKgalSNHXpLQBG9REcolNLeoR4LnP8slNMNbCFhFAiOp+uOEBvCjEiiiOuzzm2imgHL88/h7FzCP9zDdKHoETOd+Hk/uETCP9zDdKHoETOd+Hk/uETCP9zDdKHoETOd+Hk/+Dz8NhUH9/QJNAAAAAElFTkSuQmCC);
    background-repeat: no-repeat;
    background-size: 35% 35%;
    background-position: center;
}
.store-media-player .info{
    padding:10px 10px;
    font-size: 14px;
    background-color: #08001f;
    color: #f0f0f0;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
}
.store-media-player.fullscreen .panel:hover{
    opacity: 1;
    padding-top:0px;
    bottom: 0px;
}
.store-media-player.fullscreen .panel{
    padding-top:90px;
    position: absolute;
    width:100%;
    transition: all 0.5s;
    background-color: #f1f3f4;
    bottom: -90px;
    opacity: 0;
}
.store-media-player{
    position:fixed;
    bottom:0px;
    left:0px;
    right:0px;
    box-shadow: 0px 5px 10px -11px #08001f;
    width:100%;
    background-color: #f1f3f4;
    margin:auto;
}
</style>