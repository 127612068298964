<template>
    <div class="store-button"><slot></slot></div>
</template>
<script>
export default {
    data(){
        return {
            
        }
    }
}
</script>
<style scoped>
.store-button:active{
    background-color: #08001f;
    color:#ffffff;
}
.store-button{
    user-select: none;
    color:#08001f;
    border:1px solid #08001f;
    background-color:#ffffff;
    padding:14px 20px;
    text-align: center;
}
</style>