<template>
    <div class="store-upload-file-item">
        <StoreFileItem @click:name="click" :file="file">
            <div v-if="message.length > 0" class="message">{{message}}</div>
            <template v-else>
                <div v-if="typeof file.id == 'undefined'" class="action">
                    <div class="button" @click="upload()">重新上传</div>
                    <div class="button" @click="remove(index,file)">取消</div>
                </div>
                <div v-else class="action">
                    <div class="button" @click="download(file.id)">下载</div>
                    <div class="button" @click="rename(index,file)">重命名</div>
                    <div class="button delete" @click="del(index,file)">删除</div>
                </div>
            </template>
        </StoreFileItem>
    </div>
</template>
<script>
import fileObject from "@/fileobject";
import StoreFileItem from "./StoreFileItem.vue";
export default {
    data() {
        return {
            message: "",
            error: 0
        };
    },
    emits: ["uploaded","click:name"],
    props: {
        file: { type: Object, require: true },
        index:{type:Number},
        rename:{type:Function,default:function(){}},
        del:{type:Function,default:function(){}},
        remove:{type:Function,default:function(){}},
    },
    watch:{
        file(){
            if(this.file instanceof File){
                this.upload();
            }
        },
        message(nv){
            var that = this;
            if(nv.match(/[功败]$/)){
                setTimeout(function(){
                    that.message = "";
                },5000);
            }
        }
    },
    mounted(){
        if(this.file instanceof File){
            this.upload();
        }
    },
    methods: {
        click(file){
            this.$emit("click:name",file);
        },
        getFileURL(id){
            var that = this;
            this.message = "获取链接";
            return this.$http.get("/file/url/"+id).then(function(e){
                that.message = "获取成功";
                return that.$http.api+"/file/get?"+e.data.query;
            });
        },
        download(id){
            var that = this;
            if(!window.clientInformation.userAgent.match(/((chrome)|(firefox)|(edge)|(safari))/i)){
                this.getFileURL(id).then(function(url){
                    window.open(url+"&filename="+encodeURIComponent(that.file.name));
                });
                return;
            }
            this.message = "准备下载";
            this.$http.get("/file/detail/"+id).then(function(e){
                new fileObject(that.globalData.store,{
                    start(){
                        that.message = "准备下载";
                    },
                    beforeProcess(){
                        that.message = "开始下载";
                    },
                    processing(info){
                        that.message = "下载 "+(info.downloaded * 100 / info.blocks.length).toFixed(2)+' %';
                    },
                    success(file){
                        that.message = "下载成功";
                        var url = URL.createObjectURL(file);
                        // that.link = url;
                        var a = document.createElement("a");
                        a.href = url;
                        a.download = that.file.name;
                        a.target = "blank";
                        a.click();
                        setTimeout(function(){
                            URL.revokeObjectURL(url);
                            that.message = "";
                        },5000);
                    },
                    fail(){
                        that.message = "下载失败";
                    }
                }).download(e.data,8);
            }).catch(function(){
                that.message = "下载失败";
            });
        },
        upload(){
            var that = this;
            this.message = "准备上传";
            new fileObject(this.globalData.store, {
                start() {
                    that.message = "开始上传";
                },
                init() {
                    that.message = "初始化";
                },
                beforeProccess(file) {
                    return that.$http.get("/file/exist/" + file.size + "/" + file.hash).then(function (e) {
                        if (e.data.exist) {
                            // 服务器上已存在
                            file.skip = true;
                        }
                        return file;
                    }).catch(function () {
                        return file;
                    });
                },
                processing(_, unupload, index) {
                    that.message = "上传 "+(index / unupload.list.length * 100).toFixed(2) + " %";
                },
                success(file) {
                    if (!file.skip) {
                        new fileObject(that.globalData.store, {
                            success(f) {
                                that.$http.post("/file/insert", {
                                    hash: file.hash,
                                    size: file.size,
                                    last_block_size: f.lastBlockSize,
                                    block_size: f.blockSize,
                                    list: f.list
                                }).then(function (e) {
                                    if (!e.data.status) {
                                        that.message = "上传失败";
                                    }
                                    else {
                                        that.uploadFile(file);
                                    }
                                }).catch(function () {
                                    that.message = "上传失败";
                                });
                            }
                        }).upload(new Blob([file.list.join("") + (file.lastBlockSize > 0 ? file.lastBlockSize : "")]).arrayBuffer());
                    }
                    else {
                        that.uploadFile(file);
                    }
                },
                fail() {
                    that.message = "上传失败";
                },
            }).upload(this.file.arrayBuffer());
        },
        uploadFile(file) {
            var that = this;
            this.$http.post("/file/upload", {
                file_name: this.file.name,
                file_size: file.size,
                file_hash: file.hash
            }).then(function (e) {
                if (e.data.status === true) {
                    that.message = "";
                    that.$emit("uploaded", that.index,{ name: that.file.name, size: file.size, id: e.data.file_id });
                }
                else {
                    that.message = "上传失败";
                }
            });
        }
    },
    components: { StoreFileItem }
}
</script>
<style scoped>
.store-upload-file-item .message{
    margin:auto 0px;
    font-weight: bolder;
}
.store-upload-file-item .action .button.delete{
    color:#e95353;
}
.store-upload-file-item .action .button{
    margin:auto 10px;
    cursor: pointer;
}
.store-upload-file-item .action{
    display:flex;
    justify-content: space-around;
}
</style>