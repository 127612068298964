import axios from 'axios'

export default function(app, config) {
    // var api = "http://2.cn.coalcloud.node.rommhui.com:60606/api";
    // api = "http://127.0.0.1:6060/api";
    var api = config.backend;

    function token() {
        var token = document.cookie.match(/token=([^;]+)/);
        if (token) {
            return token[1];
        } else {
            return "no";
        }
    }
    app.config.globalProperties.$http = {
        api: api,
        get: function(path) {
            return axios.get(api + path, {
                headers: {
                    "token": token()
                }
            }).then(function(e) {
                if (typeof(e.headers['token']) != "undefined") {
                    document.cookie = "token=" + e.headers['token'];
                }
                return e;
            });
        },
        post: function(path, data) {
            return axios.post(api + path, data, {
                headers: {
                    "token": token()
                }
            }).then(function(e) {
                if (typeof(e.headers['token']) != "undefined") {
                    document.cookie = "token=" + e.headers['token'];
                }
                return e;
            });
        }
    }
}